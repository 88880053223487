<template>
  <section>
    <b-card
      style="border-width: 5px!important;"
      v-bind="{
        'border-variant': style.borderColor,
      }"
      @click="onClickCard"
    >
      <b-row>
        <b-col>
          <template>
            <calendar-order-card-checker
              v-bind="{
          ...order,
          ...userData,
        }"
              @check="onCheck"
            />
          </template>
        </b-col>
        <b-col>
          <p class="text-sm-truncate text-right text-danger font-weight-bolder font-medium-4">{{
              order.number_daily
            }}</p>
        </b-col>
      </b-row>

      <b-row class="mb-2">
        <b-col lg="6" xs="12">
          <p class="d-flex justify-content-between">
            <span class="font-weight-bold">Номер заказа:</span>
            <span class="text-sm-truncate text-right">{{ order.number }}</span>
          </p>
          <p class="d-flex justify-content-between flex-wrap">
            <span class="font-weight-bold flex-shrink-1">Телефон заказчика:</span>
            <span class="text-right flex-grow-1">
                <a
                  :href="phoneHref"
                  class="w-100 text-primary text-right text-nowrap"
                  @click.stop
                > {{ order.client_phone | VMask('+#(###) ###-##-##') }} </a>
            </span>
          </p>
          <p class="d-flex justify-content-between">
            <span class="font-weight-bold">Служба доставки:</span>
            <span class="text-sm-truncate text-right">{{ order.delivery_service }}</span>
          </p>
          <p class="d-flex justify-content-between">
            <span class="font-weight-bold">Имя курьера:</span>
            <span class="text-sm-truncate text-right">{{ order.courier_full_name }}</span>
          </p>
          <p class="d-flex justify-content-between flex-wrap">
            <span class="font-weight-bold pr-50">Доставка:</span>
            <span class="text-right flex-grow-1">
                 <span>c</span>
            <span class="text-truncate text-right px-50 font-weight-bold">{{
                $dayjs(order.delivery_interval_from * 1000).format('HH:mm')
              }}</span>
            <span>по</span>
            <span class="text-truncate text-right pl-50 font-weight-bold">{{
                $dayjs(order.delivery_interval_to * 1000).format('HH:mm')
              }}</span>
            </span>
          </p>
        </b-col>

        <b-col lg="6" xs="12">
          <p class="d-flex justify-content-between">
            <span class="font-weight-bold">Источник заказа:</span>
            <span class="text-sm-truncate text-right">{{ order.order_source.name }}</span>
          </p>
          <p class="d-flex justify-content-between">
            <span class="font-weight-bold">Статус оплаты:</span>
            <span v-if="order.pay_status"
                  class="text-sm-truncate text-right"
                  v-bind:class="setStatusColorClasses"
            >
                  {{ getTextPaymentStatus }}</span>
          </p>
          <p class="d-flex justify-content-between">
            <span class="font-weight-bold">Всего к оплате:</span>
            <span class="text-sm-truncate text-right">{{ order.total_price }}</span>
          </p>
          <p>
            <span class="font-weight-bold pr-1">Адрес доставки:</span>
            <span class="text-right">{{ order.delivery_address }}</span>
          </p>
        </b-col>
      </b-row>

      <template v-if="order.products && order.products.length">
        <template v-for="( product,index ) of order.products">
          <calendar-order-card-image
            :key="index"
            class="my-1"
            v-bind="{
                  ...product
                }"
            @click-img="onClickImg"
            @click-postcard="onClickPostcard"
          />
        </template>
      </template>

      <b-card-text class="d-flex p-0 justify-content-between calendar-order-action">
        <template
          v-if="(order.status !== 1) && (userData.id === order.florist_id || userData.id === order.manager_id || userData.role === roles.admin)">
          <feather-icon
            slot="action-prev"
            class="calendar-order-action__icon-prev"
            v-bind="{ icon: 'ArrowLeftCircleIcon', size: '3x', fill: 'red', color:'white', cursor: 'pointer'}"
            @click="onChangeStatus({ $event, offset: -1 })"
          ></feather-icon>
        </template>

        <template
          v-if="(order.status !== 4) && (userData.id === order.florist_id || userData.id === order.manager_id || userData.role === roles.admin)">
          <feather-icon
            slot="action-next"
            class="calendar-order-action__icon-next"
            v-bind="{ icon: 'ArrowRightCircleIcon', size: '3x', fill: 'red', color:'white', cursor: 'pointer'}"
            @click="onChangeStatus({ $event, offset: 1 })"
          ></feather-icon>
        </template>

        <template v-if="(order.status === 4 && (userData.id === order.manager_id || userData.role === roles.admin))">
          <feather-icon
            slot="action-next"
            class="calendar-order-action__icon-next"
            v-bind="{ icon: 'ArrowRightCircleIcon', size: '3x', fill: 'red', color:'white', cursor: 'pointer'}"
            @click="onChangeStatus({ $event, offset: 1 })"
          ></feather-icon>
        </template>
      </b-card-text>
    </b-card>

    <!-- Modal -->
    <common-dialog
      v-bind="{
        value: modal.image.visible || modal.postcard.visible,
        hideHeader: modal.image.visible || !modal.postcard.visible ? true : false,
        hideFooter: true,
        title: modal.image.visible || !modal.postcard.visible ? '' : 'Текст открытки',
        size: 'md',
        modalClass: modal.image.visible ? 'app-modal-img' : '',
      }"
      @confirm="onConfirm"
    >
      <template v-if="modal.image.visible">
        <div><img :src="activeImgUrl"/></div>
      </template>

      <template v-if="modal.postcard.visible">
        <div>
          <span class="calendar-composition-comment">{{ activePostcardText }}</span>
        </div>
      </template>
    </common-dialog>
    <!-- Modal -->
  </section>
</template>

<script>
import { duration } from '@/config'
import { AuthNameSpace, RoleCollection } from '@/store/modules/auth/types'
import { OrderNameSpace, OrderActionTypes } from '@/store/modules/order/types'
import { mapState, mapActions } from 'vuex'
import { throttle } from 'lodash'
import clearDeep from 'clean-deep'
import { orderPaymentStatusCollection } from '@/config/shared';

export default {
  name: 'CalendarOrderCard',

  components: {
    CalendarOrderCardInfo: () => import('./CalendarOrderCardInfo.vue'),
    CalendarOrderCardComment: () => import('./CalendarOrderCardComment.vue'),
    CalendarOrderCardChecker: () => import('./CalendarOrderCardChecker.vue'),
    CalendarOrderCardImage: () => import('./CalendarOrderCardImage.vue'),
    CommonDialog: () => import('@/components/common/common-dialog.vue'),
  },

  props: {
    order: {
      type: Object,
      default() {
        return {}
      },
    },
    imgTop: {
      type: Boolean,
      default() {
        return true
      },
    },
  },

  data() {
    return {
      checkedByManager: false,
      activeImgUrl: '',
      activePostcardText: '',
      modal: {
        image: { visible: false },
        postcard: { visible: false },
      },
      orderPaymentStatusCollection,
    }
  },
  methods: {
    ...mapActions(OrderNameSpace, {
      [OrderActionTypes.UpdateOrder]: OrderActionTypes.UpdateOrder,
    }),

    updateOrder: throttle(async function(data) {
      await this[OrderActionTypes.UpdateOrder](data)

      this.$emit('updateOrder')
    }, duration),

    async onCheck({ florist_id }) {
      if (this.userData.role === this.roles.manager) {
        this.checkedByManager = !this.checkedByManager
        return
      }

      if (this.userData.role === this.roles.florist) {
        await this.updateOrder({
          id: this.order.id,
          florist_id,
        })
      }
    },

    async onChangeStatus({ $event, offset }) {
      $event.stopPropagation()

      // TODO handle self delivery

      const { status, delivery_status } = this.order

      if (status === 2 && delivery_status === 0 && offset === 1) {
        offset = 2
      }

      if (status === 4 && delivery_status === 0 && offset === -1) {
        offset = -2
      }

      const data = Object.assign(this.order, {
        id: this.order.id,
        status: this.order.status + offset,
      })

      await this.updateOrder(clearDeep(data))
    },

    onClickCard($event) {
      this.$router.push({ name: 'order-base-item', params: { id: this.order.id } })
    },

    onClickImg({ src }) {
      this.modal.image.visible = true
      this.activeImgUrl = src
    },

    onClickPostcard({ text }) {
      this.modal.postcard.visible = true
      this.activePostcardText = text
    },

    onConfirm() {
      for (const type in this.modal) {
        if (this.modal[type].visible) {
          this.modal[type].visible = false
        }
      }
    },
  },

  computed: {
    ...mapState(AuthNameSpace, {
      userData: 'userData',
    }),
    roles() {
      return RoleCollection
    },
    style() {
      return {
        borderColor: this.order.status === 1 ? 'secondary' : this.order.status === 2 ? 'success' : this.order.status === 3 ? 'info' : 'warning',
      }
    },

    getTextPaymentStatus() {
      if (this.order.pay_status) {
        return this.orderPaymentStatusCollection.find(item => item.value === this.order.pay_status).text
      }
      return 'нет данных';
    },

    setStatusColorClasses() {
      return {
        'text-success': this.order.pay_status === 1,
        'text-danger': this.order.pay_status > 1,
      }
    },

    phoneHref() {
      return `tel:+${this.order.client_phone}`
    },
  },
}
</script>

<style lang="scss" scoped>
.order__icon-comment {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.calendar-order-action {
  margin-bottom: -2em;
}

.calendar-order-action__icon-next {
  margin-left: auto;
  margin-right: -2em;
}

.calendar-order-action__icon-prev {
  margin-right: auto;
  margin-left: -2em;
}

.calendar-composition-comment {
  word-break: break-all;
}


</style>
